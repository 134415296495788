import React from 'react';

const Section = ({ className, children }) => (
    <section className={ `section ${ className ?? '' }` }>
        <section className="section__parent-container">
            { children }
        </section>
    </section>
);

export default Section;
