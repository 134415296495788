export const GenerateSEO = ({ is404, title, description, image, imageAlt, url }) => (
    {
        is404,
        lang: 'en',
        title,
        description: FilterDescription(description),
        image,
        imageAlt,
        url
    }
);

const FilterDescription = description => {
    description = description.toUpperCase() === 'PLACEHOLDER' ? '' : description;
}
